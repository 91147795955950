@font-face {
  font-family: 'Druk Wide';
  src: url('./assets/fonts/druk/Druk-WideMedium.woff2') format('woff2'),
      url('./assets/fonts/druk/Druk-WideMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AmperSans';
  font-style: normal;
  font-weight: normal;
  src: local('AmperSans Regular'), url('./assets/fonts/AmperSans/AmperSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'AmperSans';
  font-style: normal;
  font-weight: 500;
  src: local('AmperSans Medium'), url('./assets/fonts/AmperSans/AmperSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'AmperSans';
  font-style: normal;
  font-weight: 800;
  src: local('AmperSans Bold'), url('./assets/fonts/AmperSans/AmperSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Graphik Wide';
  src: url('./assets/fonts/graphikwide/GraphikWide-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/graphikwide/GraphikWide-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RLUUTYReckless';
  src: url('./assets/fonts/rluuty/RLUUTYReckless-Regular.woff2') format('woff2'),
      url('./assets/fonts/rluuty/RLUUTYReckless-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


body {
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.underline {
  position: relative;
  color: inherit;
  border-bottom: 2px solid #FECB36;
  cursor: pointer;
}
